.app .product .product-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.app .product .product-gallery {
  max-width: 49%;
  min-height: 300px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.app .product .product-gallery img {
  max-height: 300px;
}

.app .product .product-gallery .image-gallery {
  max-width: 100%;
}

.app .product .product-gallery .image-gallery-thumbnails {
  margin-top: 1em;
}

.app .product .product-gallery .image-gallery-thumbnail {
  border: 3px solid transparent;
  border-radius: 5px;
}

.app .product .product-gallery .image-gallery-thumbnail:hover {
  border: 3px solid rgb(71, 155, 173);
}

.app .product .product-gallery .image-gallery-thumbnail img {
  max-height: 120px;
  max-width: 100%;
  width: auto;
}

.app .product .product-gallery .image-gallery-left-nav,
.app .product .product-gallery .image-gallery-right-nav {
  padding: 0;
  opacity: 0.25;
}

.app .product .product-gallery .image-gallery-left-nav:hover,
.app .product .product-gallery .image-gallery-right-nav:hover {
  opacity: 1;
}

.app .product .product-gallery .image-gallery-left-nav svg,
.app .product .product-gallery .image-gallery-right-nav svg {
  transform: scale(0.5);
}

.app .product .product-gallery .image-gallery-icon:hover {
  color: rgb(71, 155, 173);
}

.app .product .product-gallery .active {
  border: 3px solid rgb(71, 155, 173);
}

.app .product .product-main {
  max-width: 600px;
  padding-left: 3em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
}

.app .product-main .top-container {
  background: rgb(50, 93, 99);
  padding: 1em;
  margin-bottom: 1em;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
  position: relative;
  overflow: hidden;
}

.app .product-main .on-sale-banner {
  background: #e32c2bcc;
  font-family: serif;
  color: #eee;
  font-weight: 700;
  transform: rotate(45deg);
  position: absolute;
  right: -50px;
  top: -14px;
  padding: 30px 3em 2px 3em;
}

.app .product-main .bottom-container {
  padding-right: 4em;
  display: flex;
  flex-direction: column;
}

.app .product-main .brand {
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 0;
  color: #eee;
}

.app .product-main .name {
  text-align: left;
  text-transform: uppercase;
  font-size: 40px;
  color: #eee;
}

.app .product-main .price {
  color: #666;
}

.app .product-main .price.on-sale {
  color: red;
}

.app .product-main .size-select:hover .ant-select-selector {
  border: 1px solid #e32c2b;
}

.app .product-main .info-line {
  color: #666;
  margin-top: 1em;
}

.app .product-main .sub-title {
  font-size: 24px;
  margin: 26px 0;
}

.app .product-main .more-info-link {
  margin: 1em 0;
}

.app .product-main .please-contact {
  text-decoration: underline;
  font-size: 1.1em;
  margin: 1em 0;
}

.app .product .product-menu .ant-menu {
  margin-top: 1em;
  justify-content: center;
  background: rgb(50, 93, 99);
  color: #fff9;
  color: #eee;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
}

.app .product-menu .ant-menu .ant-menu-item:hover,
.app .product-menu .ant-menu .ant-menu-item-selected {
  color: #eee;
}

.app .product-menu .ant-menu .ant-menu-item:hover::after,
.app .product-menu .ant-menu .ant-menu-item-selected::after,
.app .product-menu .ant-menu .ant-menu-item-selected:hover::after {
  border-bottom: 3px solid #eee;
  border-right: none;
  margin-bottom: 1px;
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  top: auto;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
  transform: none;
  opacity: 1;
}

.app .product-more {
  width: 100%;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .product .desc {
  white-space: pre-wrap;
  max-width: 840px;
}

.app .product .photos {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.app .product .product-more .image-gallery {
  max-width: 80vw;
  min-height: 80vh;
}

.app .product .product-more .image-gallery-thumbnail {
  border: 3px solid transparent;
  overflow: hidden;
}

.app .product .product-more .image-gallery-thumbnail-inner img {
  width: auto;
  max-height: 80px;
}

.app .product .product-more .image-gallery-thumbnail:hover {
  border: 3px solid rgb(71, 155, 173);
}

.app .product .product-more .image-gallery-icon:hover {
  color: rgb(71, 155, 173);
}

.app .product .product-more .image-gallery-left-nav,
.app .product .product-more .image-gallery-right-nav {
  opacity: 0.25;
}

.app .product .product-more .image-gallery-left-nav:hover,
.app .product .product-more .image-gallery-right-nav:hover {
  opacity: 1;
}

.app .product .product-more .active {
  border: 3px solid rgb(71, 155, 173);
}

.app .product .desc p {
  margin-bottom: 0;
}

.app .product-more .specs {
  width: 100%;
}

.app .product-more .specs .ant-table {
  background: none;
}

.app .product-more .specs .ant-table-thead .ant-table-cell {
  background: #eee;
}

.app .product-more .specs .ant-table-tbody > tr > td.ant-table-cell {
  background: none;
}

.app .product-more .specs .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #f5f5f5;
}

.app .product-more ul {
  padding-inline-start: 0;
}

.app .product .product-video {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1.5em 0;
}

.app .product .product-video iframe {
  width: 800px;
  height: 400px;
}

.app .product .other-products {
  margin-top: 4em;
}

.app .product .other-products .other-heading {
  text-transform: uppercase;
  background: rgb(50, 93, 99);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #eee;
  padding: 0.1em 0;
}

@media only screen and (max-width: 1000px) {
  .app .product .product-main {
    padding: 0;
    max-width: 100%;
  }

  .app .product .product-video iframe {
    width: 300px;
    height: 200px;
  }

  .app .product-more {
    padding: 0;
  }

  .app .product-more .desc {
    padding: 1.5em;
  }

  .app .product .product-gallery .image-gallery {
    max-width: 100vw;
    padding: 1em 0;
  }

  .app .product .product-gallery {
    max-width: 100vw;
    padding: 0.5em;
  }

  .app .product-main .top-container {
    padding: 1em 1.5em;
  }

  .app .product-main .bottom-container {
    padding: 1.5em;
  }

  .app .product .product-more .image-gallery {
    max-width: 100vw;
  }

  .app .product .product-gallery .image-gallery-left-nav,
  .app .product .product-gallery .image-gallery-right-nav {
    padding: 1em;
  }

  .app .product .product-gallery .image-gallery-left-nav svg,
  .app .product .product-gallery .image-gallery-right-nav svg {
    transform: scale(1.5);
  }

  .app .product .ant-menu .ant-menu-item-selected {
    background-color: #0000;
  }

  .app .product .ant-menu .ant-menu-item:hover {
    background-color: #0000;
  }

  .app .product-menu .ant-menu .ant-menu-item:hover::after,
  .app .product-menu .ant-menu .ant-menu-item-selected::after,
  .app .product-menu .ant-menu .ant-menu-item-selected:hover::after {
    border-bottom: 3px solid #eee;
    border-right: none;
    margin-bottom: 0;
    position: absolute;
    right: 20px;
    bottom: 0;
    left: 20px;
    top: auto;
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: '';
    transform: none;
    opacity: 1;
  }
}
