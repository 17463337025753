.app .home .ant-carousel {
  margin-bottom: -60px;
  overflow: hidden;
  box-shadow: 0 -20px 100px 10px rgba(46, 46, 46, 0.5);
}

.app .home .ant-carousel .slick-dots {
  bottom: 80px;
}

.app .home .ant-carousel .slick-dots li {
  margin: 5px;
}

.app .home .ant-carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.app .home .ant-carousel .slick-active {
  width: 16px;
}

.app .home .ant-carousel li.slick-active button {
  background: rgba(255, 255, 255, 0.75);
  border: 2px solid rgba(58, 128, 143, 0.75);
}

.app .home .carousel-item {
  height: 68vh;
  position: relative;
  display: flex;
  justify-content: center;
}

.app .home .carousel-img {
  width: 100%;
  height: 100%;
  background-position: center bottom;
  background-repeat: repeat;
  background-size: contain;
}

.app .home .carousel-img img {
  min-width: 100vw;
  z-index: 1;
  bottom: 0;
  position: absolute;
}

.app .home .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0 !important;
}

.app .home .content-row {
  width: 100%;
  max-width: 1180px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 998;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 6px;
}

.app .home .content-col {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.app .home .content-subrow {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.app .home .product-cat-button {
  height: calc(min(332.5px, 29.5vw));
  width: 25%;
  min-width: 250px;
  flex-grow: 1;
  padding: 0;
  border: none;
  border-radius: 0;
  color: #e32c2b;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(180deg, rgba(46, 46, 46, 1) 0%, rgba(69, 69, 69, 1) 12%, rgb(243, 243, 243) 30%);
  transition: all 0.35s linear;
}

.app .home .product-cat-button:hover {
  color: #ab1717;
  background: #eeeeee;
  background: linear-gradient(180deg, rgba(46, 46, 46, 1) 0%, rgb(230, 230, 230) 12%, rgba(238, 238, 238, 1) 30%);
}

.app .home .product-cat-button-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app .home .product-cat-button-header {
  white-space: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.app .home .product-cat-button-header h1 {
  font-family: 'Lora', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  min-height: 111px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(46, 46, 46);
  color: #eee;
  border-top: 1px solid rgba(20, 20, 20, 0.2);
  border-bottom: 1px solid rgba(20, 20, 20, 0.2);
  transition: all 0.35s linear;
  margin-bottom: 0;
}

.app .home .product-cat-button:hover h1 {
  background: rgb(60, 60, 60);
  color: #eee;
}

.app .home .product-cat-button-img {
  height: 60%;
  padding: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app .home .product-cat-button-img img {
  max-width: 100%;
  max-height: 230px;
}

.app .home .blog-main-button {
  height: 138px;
  width: 100%;
  border: none;
  border-radius: 0;
  color: #e32c2b;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  background: linear-gradient(180deg, rgba(46, 46, 46, 1) 0%, rgba(69, 69, 69, 1) 45%, rgba(250, 250, 250, 1) 72%);
  transition: all 0.35s linear;
  border-top: solid rgba(117, 117, 117, 0.33) 0.5px;
}

.app .home .blog-main-button:hover {
  color: #ab1717;
  background: #eeeeee;
  background: linear-gradient(180deg, rgba(46, 46, 46, 1) 0%, rgba(238, 238, 238, 1) 45%, rgb(255, 255, 255) 75%);
}

.app .home .blog-main-button h1 {
  font-family: 'Lora', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 0.25em;
  white-space: normal;
  min-height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(46, 46, 46);
  color: #eee;
  border-top: 1px solid rgba(20, 20, 20, 0.2);
  border-bottom: 1px solid rgba(20, 20, 20, 0.2);
  transition: all 0.35s linear;
}

.app .home .blog-main-button:hover h1 {
  background: rgb(60, 60, 60);
  color: #eee;
}

.app .home .blog-main-button .read-all {
  font-size: 1.2em;
  margin-top: 0.75em;
  margin-bottom: -0.5em;
}

.app .home .blog-main-button:hover .read-all {
  font-weight: 700;
}

.app .home .blog-button {
  height: auto;
  min-height: 122px;
  width: 25%;
  min-width: 250px;
  flex-grow: 1;
  border: none;
  border-radius: 0;
  color: #e32c2b;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background 0.35s linear;
  background: #fafafa;
}

.app .home .blog-button:hover {
  color: #ab1717;
  font-weight: 700;
  background: rgba(238, 238, 238, 1);
}

.app .home .blog-button:first-child {
  border-radius: 0 0 0 6px;
}

.app .home .blog-button:last-child {
  border-radius: 0 0 6px 0;
}

.app .home .blog-button .date {
  color: #666;
  font-weight: 400;
}

.app .home .blog-button h6 {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0.25em;
  white-space: normal;
}

.app .home .blog-button img {
  max-height: 150px;
  margin-bottom: 1em;
}

.app .home .bottom-spacer {
  margin-bottom: 24px;
}

@media only screen and (max-width: 1020px) {
  .app .home .ant-carousel {
    margin-bottom: 0;
  }

  .app .home .carousel-item {
    height: calc(min(125vw, 70vh));
  }

  .app .home .ant-carousel .slick-dots {
    bottom: 5px;
  }

  .app .home .content-row {
    border: none;
  }

  .app .home .product-cat-button {
    height: auto;
    border-radius: 0 0 6px 6px !important;
    min-width: calc(max(250px, 50%));
  }

  .app .home .product-cat-button-content {
    padding: 0;
  }

  .app .home .product-cat-button-content h1 {
    margin-bottom: 0;
  }

  .app .home .product-cat-button-img {
    padding: 0;
  }

  .app .home .product-cat-button-img img {
    max-height: 75vw;
  }

  .app .home .blog-button {
    height: auto;
  }

  .app .home .blog-main-button {
    border-top: none;
  }

  .app .home .bottom-spacer {
    margin-bottom: 0;
  }
}
