.app .ant-layout-footer {
  background: linear-gradient(60deg, rgba(35, 75, 84, 1) 0%, rgba(0, 0, 0, 1) 65%, rgba(58, 128, 143, 1) 100%);
  color: #fff9;
  padding: 0;
}

.app .ant-layout-footer .links-bar {
  display: flex;
  justify-content: center;
}

.app .ant-layout-footer .links-cols {
  width: 100%;
  max-width: 1180px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 0;
}

.app .ant-layout-footer .links-col {
  min-width: 260px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.app .ant-layout-footer .links-col-outer {
  display: flex;
  flex-direction: column;
}

.app .ant-layout-footer .links-col .waiver-button {
  box-shadow: 0 0 5px #afafaf;
  border: 1px solid #ddd;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 5px 20px;
  border-radius: 7px;
  text-align: center;
  font-size: 20px;
  background-color: #fb910a;
  color: #eee;
}

.app .ant-layout-footer .links-col .waiver-button:hover {
  color: #111;
  border: 1px solid #fff;
}

.app .ant-layout-footer .links-col h4 {
  color: #fff;
  font-weight: 700;
  font-style: normal;
  font-family: 'Lora', serif;
  font-size: 24px;
  margin-bottom: 17px;
}

.app .ant-layout-footer .links-col a {
  color: #fff9;
  font-weight: 400;
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.app .ant-layout-footer .links-col > a {
  margin-bottom: 10px;
}

.app .ant-layout-footer .links-col p {
  margin-bottom: 10px;
}

.app .ant-layout-footer .links-col a:hover {
  color: #fff;
}

.app .ant-layout-footer .socials a {
  font-size: 1.8em;
  margin: 6px;
}

.app .ant-layout-footer .bottom-bar {
  border-top: 1px solid rgba(58, 128, 143, 0.25);
  display: flex;
  justify-content: center;
}

.app .copyright {
  width: 100%;
  max-width: 1180px;
  padding: 10px;
  font-size: 11px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}

@media only screen and (max-width: 1000px) {
  .app .ant-layout-footer .links-col {
    margin: 10px 10px;
  }
}
