.app .products {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .products .content {
  width: 100%;
  max-width: 1180px;
  background: rgb(253, 253, 253);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.app .products h1 {
  font-weight: 700;
  font-style: normal;
  font-family: 'Lora', serif;
  font-size: 24px;
  margin-bottom: 0;
  text-align: center;
}

.app .products .ant-divider {
  margin: 12px 0 16px 0;
}

.app .products .breadcrumb {
  margin: 1em 1.5em;
}

.app .products .breadcrumb button {
  color: #e32c2b;
}

.app .products .breadcrumb button:hover {
  color: #ab1717;
}

.app .products .product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2em;
}

.app .products .product-list-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0 2em;
}

.app .products .product-list-controls .breadcrumb {
  flex-grow: 1;
  margin: 1em 0 0 0;
}

.app .products .product-control {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.app .products .product-control-label {
  text-transform: uppercase;
  color: #666;
  font-size: 0.9em;
}

.app .products .product-list-controls .ant-select:hover .ant-select-selector {
  border: 1px solid #e32c2b;
}

@media only screen and (max-width: 1000px) {
  .app .products .breadcrumb .ant-btn {
    padding: 0.5em 0;
  }

  .app .products .product-control {
    flex-grow: 1;
    min-width: 70%;
    padding: 0.5em 0;
  }
}
