.app .blog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .blog .content {
  width: 100%;
  max-width: 1180px;
  background: rgb(248, 248, 248);
  padding: 0 3em;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.app .blog h1 {
  font-weight: 700;
  font-style: normal;
  font-family: 'Lora', serif;
  font-size: 24px;
  margin-bottom: 17px;
  text-align: center;
  color: #eee;
}

.app .blog .blog-button {
  margin: 2em 0;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 6px 6px;
}

.app .blog .blog-button .heading {
  width: 100%;
  padding-top: 1em;
  background: linear-gradient(180deg, rgba(46, 46, 46, 1) 0%, rgba(69, 69, 69, 1) 12%, rgba(255, 255, 255, 1) 100%);
}

.app .blog .blog-button .image {
  max-height: 300px;
  max-width: 80vw;
  margin-bottom: 2em;
}

.app .blog .blog-button .date {
  padding-top: 1em;
  background: #333;
  width: 100%;
  text-align: center;
  color: #eee;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.app .blog .blog-button h1 {
  white-space: normal;
  font-size: 20px;
  background: #333;
  width: 100%;
  padding: 0.5em 0.25em 1em 0.25em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.app .blog .blog-button .text {
  padding: 2em 4em;
  width: 100%;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: 22px;
  max-height: 120px;
}

.app .blog .blog-button .text p {
  margin-bottom: 0;
}

.app .blog .blog-button .read-link {
  font-size: 1.15em;
  font-weight: bold;
}

.blog .pagination {
  padding-top: 2em;
}

@media only screen and (max-width: 1000px) {
  .app .blog .content {
    padding: 0;
  }

  .app .blog .blog-button {
    border-left: none;
    border-right: none;
  }

  .app .blog .blog-button .text {
    padding: 1em 2em;
    line-clamp: 6;
    -webkit-line-clamp: 6;
    max-height: 150px;
  }
}
