.app .hg-lessons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .hg-lessons .content {
  width: 100%;
  max-width: 1180px;
  background: rgb(253, 253, 253);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 2em;
  padding-top: 2em;
}

.app .hg-lessons h1 {
  font-weight: 700;
  font-style: normal;
  font-family: 'Lora', serif;
  font-size: 24px;
  margin-bottom: 17px;
  text-align: center;
}

.app .hg-lessons .row {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.app .hg-lessons .row:not(.subrow) {
  padding: 0 1em;
}

.app .hg-lessons .col {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app .hg-lessons .col:not(.subcol) {
  min-width: 300px;
}

.app .hg-lessons .col:not(.wide) {
  width: 50%;
}

.app .hg-lessons .dates {
  color: #ff2a00;
}

.app .hg-lessons .register-link button {
  margin-top: 1em;
}

.app .hg-lessons .register-link button:hover {
  color: #e32c2b;
  border: 1px solid #e32c2b;
}

.app .hg-lessons .no-register-link {
  margin-top: 1em;
}

.app .hg-lessons .ant-carousel {
  overflow: hidden;
}

.app .hg-lessons .ant-carousel .slick-dots {
  bottom: 0;
}

.app .hg-lessons .ant-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  display: none;
}

.app .hg-lessons .ant-carousel .slick-active {
  width: 16px;
}

.app .hg-lessons .ant-carousel li.slick-active button {
  background: rgba(255, 255, 255, 0.75);
  border: 2px solid rgba(58, 128, 143, 0.75);
}

.app .hg-lessons .carousel-item {
  height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
}

.app .hg-lessons .carousel-img {
  width: 100%;
  height: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.app .hg-lessons .dates-section {
  text-align: center;
}

.app .hg-lessons img.static {
  height: auto;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .app .hg-lessons .carousel-item {
    height: 40vh;
  }

  .app .hg-lessons .col:not(.subcol) {
    padding: 0.75em 0;
    min-width: 90vw;
  }

  .app .hg-lessons .subcol {
    padding: 0;
  }

  .app .hg-lessons ul {
    padding-inline-start: 20px;
  }
}
