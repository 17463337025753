.html {
  --antd-wave-shadow-color: rgba(58, 128, 143, 1) !important;
}

.app {
  height: 100vh;
}

::selection {
  background: rgba(58, 128, 143, 0.5) !important;
}

.app a {
  color: #e32c2b;
}

.app a:hover {
  color: #ab1717;
}

.app .ant-btn-link {
  color: #e32c2b;
  padding: 1em 0;
  height: auto;
}

.app .ant-btn-link:hover {
  color: #ab1717;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(58, 128, 143, 0.5) !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #ab1717 !important;
  box-shadow: none !important;
}

.app .ant-layout {
  min-height: 100vh;
}

.app .loading {
  width: 100%;
  height: 100%;
  padding-top: 30vh;
  display: flex;
  justify-content: center;
}

.app .loading.load-page {
  padding-top: 20vh;
  margin-bottom: 1000px;
}

.app .ant-spin {
  transform: scale(1.5);
}

.app .loading .ant-spin-dot-item {
  background-color: rgba(58, 128, 143, 1);
  transform: scale(3);
}

.app .page-title {
  width: 100%;
  padding: 0.25em;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  font-family: 'Lora', serif;
  text-align: center;
  text-transform: uppercase;
  color: #fffd;
  text-shadow: -1px 1px 1px #22222244, 1px -1px 1px #22222244, -1px -1px 1px #22222244, 1px 1px 1px #22222244;
  background: linear-gradient(60deg, rgba(0, 0, 0, 1) 30%, rgba(35, 75, 84, 1) 65%, rgba(58, 128, 143, 1) 100%);
  box-shadow: rgba(33, 35, 38, 0.5) 0px 10px 10px -10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 0 !important;
  z-index: 997;
}

.app .content {
  overflow: hidden;
  padding-bottom: 4em !important;
}

.app .pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

.app .pagination .ant-pagination-item-active {
  border: 1px solid rgba(66, 143, 160, 0.85);
}

.app .pagination .ant-pagination-item:hover {
  border: 1px solid rgba(66, 143, 160, 0.65);
}

.app .pagination .ant-pagination-prev:hover button {
  border: 1px solid rgba(66, 143, 160, 0.65);
  color: rgba(66, 143, 160, 1);
}

.app .pagination .ant-pagination-next:hover button {
  border: 1px solid rgba(66, 143, 160, 0.65);
  color: rgba(66, 143, 160, 1);
}

.app .pagination .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.85);
}

.app .pagination .ant-pagination-item:hover a {
  color: rgba(66, 143, 160, 1);
}

.ant-input:focus {
  box-shadow: 0 0 0 2px rgba(58, 128, 143, 0.4) !important;
}

.app .invisible {
  display: none;
}

.app .half-invisible {
  opacity: 0.3;
}

@media only screen and (max-width: 1000px) {
  .app .page-title {
    background: linear-gradient(60deg, rgba(0, 0, 0, 1) 47%, rgba(35, 75, 84, 1) 80%, rgba(58, 128, 143, 1) 100%);
  }
}
