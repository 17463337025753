.app .blog-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .blog-item .content {
  width: 100%;
  max-width: 1180px;
  background: rgb(248, 248, 248);
  padding: 1em 4em;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .blog-item h1 {
  font-weight: 700;
  font-style: normal;
  font-family: 'Lora', serif;
  font-size: 20px;
  margin-bottom: 17px;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .app .blog-item .content {
    padding: 1em;
  }
}
