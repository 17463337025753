.app .product-button {
  height: 320px;
  width: 250px;
  border: none;
  margin: 1em;
  padding: 0;
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0 6px 6px;
}

.app .product-button:hover {
  color: #000;
  background: #fefefe;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px 0px;
  border: 1px solid rgba(66, 143, 160, 0.55);
}

.app .product-button .name {
  font-size: 1.25em;
  white-space: normal;
  background: rgb(71, 71, 71);
  color: #eee;
  border-bottom: 1px solid rgba(20, 20, 20, 0.2);
  transition: all 0.25s linear;
  padding: 0 0.5em 0.5em 0.5em;
}

.app .product-button:hover .name {
  color: #e42222;
  background: #ddd;
}

.app .product-button .brand {
  background: rgb(71, 71, 71);
  color: #eee;
  border-top: 1px solid rgba(20, 20, 20, 0.2);
  padding-top: 0.5em;
  transition: all 0.25s linear;
}

.app .product-button:hover .brand {
  color: #e42222;
  background: #ddd;
}

.app .product-button .price {
  font-size: 1.05em;
}

.app .product-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.app .product-content .on-sale-banner {
  background: #e32c2bee;
  font-family: serif;
  color: #eee;
  font-weight: 700;
  transform: rotate(45deg);
  position: absolute;
  right: -50px;
  top: -14px;
  padding: 30px 3em 2px 3em;
  opacity: 1;
}

.app .product-content .image {
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.app .product-content .image .sub-title {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 1em;
  background: rgba(75, 75, 75, 0.92);
  color: #eee;
  white-space: normal;
  font-size: 12px;
  opacity: 0;
  transition: all 0.25s linear;
}

.app .product-button .sub-title:hover {
  opacity: 1;
}

.app .product-content .image .ant-carousel {
  width: 100%;
}

.app .carousel-item {
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.app .carousel-item img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 182px;
}

.app .product-content .text {
  padding: 0 0 1em 0;
}

.app .product-content .name {
  font-weight: 700;
}

.app .product-content .price {
  color: #666;
}

.app .product-content .price.on-sale {
  color: red;
}

@media only screen and (max-width: 1000px) {
  .app .product-button {
    width: calc(min(350px, 100vw - 4em));
  }
}
