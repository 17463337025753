.app .add-product {
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1300px;
  width: 100%;
}

.app .add-product .top {
  width: 100%;
  display: flex;
}

.app .add-product .preview {
  margin-bottom: 2em;
  flex-grow: 1;
  text-align: center;
}

.app .add-product .ant-switch-checked {
  background: rgba(58, 128, 143, 1);
}

.app .add-product .ant-switch:not(.ant-switch-checked) {
  background: rgb(37, 42, 43);
}

.app .add-product .previewing {
  width: 100%;
  background: #eee;
  border-radius: 15px;
}

.app .add-product h1 {
  font-weight: 700;
  font-style: normal;
  font-family: 'Lora', serif;
  font-size: 24px;
  margin-bottom: 17px;
  text-align: center;
}

.app .add-product .form {
  width: 100%;
}

.app .add-product .form .ant-collapse {
  width: 100%;
}

.app .add-product .ant-form-item-label {
  width: 150px;
}

.app .add-product .small-fields {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.app .add-product .small-fields .ant-form-item {
  width: 50%;
  min-width: 300px;
}

.app .add-product .product-sizes {
  margin: 0px 150px 20px 150px;
}

.app .add-product .product-sizes .ant-form-item {
  margin-bottom: 8px;
}

.app .add-product .add-size-button {
  max-width: 420px;
}

.app .add-product .image-upload {
  width: 100%;
}

.app .add-product .pending-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.app .add-product .pending-image {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.app .add-product .pending-image img {
  max-width: 80px;
  margin: 0.25em;
}

.app .add-product .pending-image .path {
  margin: 0.25em;
}

.app .add-product .pending-image .ant-switch {
  margin: 0.5em 0;
}

.app .add-product .pending-image .delete-button {
  border-radius: 20px;
  background: none;
  padding: 0.25em 0.5em;
  height: auto;
}

.app .add-product .pending-image .delete-button.undo {
  border-radius: 20px;
  background: rgb(238, 128, 128);
  color: #eee;
}

.app .add-product .product-specs .ant-form-item {
  margin-bottom: 0.5em;
}

.app .add-product .submit-button {
  margin-top: 1em;
}

@media only screen and (max-width: 1000px) {
  .app .add-product {
    padding: 1em 0.5em;
  }

  .app .add-product .product-sizes {
    margin: 20px 0;
  }
}
