.app .edit-prices {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .edit-prices .product-selector {
  width: 100%;
  display: flex;
}

.app .edit-prices .ant-collapse-content .ant-collapse {
  background: none;
}

.app .edit-prices .product-prices {
  display: flex;
}

.app .edit-prices .product-prices .ant-form-item {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 24px;
}

.app .edit-prices .product-prices .form-row {
  display: flex;
}

.app .edit-prices .product-prices .form-row button {
  margin-right: 1em;
}

.app .edit-prices .product-prices .ant-space .ant-form-item {
  margin-top: 0;
  margin-bottom: 0;
}

.app .edit-prices .product-prices .ant-switch-checked {
  background: rgba(58, 128, 143, 1);
}

.app .edit-prices .product-prices .option-button {
  padding-top: 4px;
}

.app .edit-prices .product-prices .add-option-button {
  margin-right: 0;
}

.app .edit-prices .product-prices .option-name-input {
  max-width: 200px;
}

.app .edit-prices .product-prices .option-price-input {
  max-width: 110px;
}
