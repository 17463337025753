.app .admin .login {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5em 1em;
  background: #eee;
}

.app .admin .ant-input-password {
  background: none !important;
}
