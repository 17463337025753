.app .payment {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .payment .content {
  width: 100%;
  max-width: 1180px;
  background: rgb(253, 253, 253);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 2em;
  padding-top: 2em;
}

.app .payment h1 {
  font-weight: 700;
  font-style: normal;
  font-family: 'Lora', serif;
  font-size: 24px;
  margin-bottom: 17px;
  text-align: center;
}

.app .payment .row {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1em 5em;
}

.app .payment .col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .payment .form-wrapper {
  display: flex;
  justify-content: center;
}

.app .payment .form-button-wrapper {
  margin-left: 2em;
}

.app .payment button:hover {
  color: #e32c2b;
  border: 1px solid #e32c2b;
}

.app .payment button:focus {
  color: #e32c2b;
  border: 1px solid #e32c2b;
}

.app .payment input:hover {
  border: 1px solid #e32c2b;
}

.app .payment input:focus {
  border: 1px solid #e32c2b;
}