.app .search {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .search .content {
  width: 100%;
  max-width: 1180px;
  background: rgb(253, 253, 253);
  padding: 3em 6em;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.app .search .ant-list {
  margin-bottom: 2em;
}

.app .search .ant-list-item {
  cursor: pointer;
}

.app .search .ant-list-item:hover h4 {
  color: red;
}

.app .search .no-results {
  width: 100%;
  text-align: center;
  font-style: italic;
  margin-top: 1em;
}

.app .search .search-input {
  width: 100%;
  display: flex;
  justify-content: center;
}

.app .search .search-input .ant-form {
  width: 100%;
  max-width: 400px;
  display: flex;
}

.app .search .search-input .ant-form-item {
  flex-grow: 1;
}

.app .search .search-input input:hover {
  border-color: rgba(66, 143, 160, 1);
}

.app .search .search-input input:focus {
  border-color: rgba(66, 143, 160, 1);
  box-shadow: 0 0 0 2px rgb(66, 143, 160, 0.2);
}

.app .search .search-input button {
  padding: 0 0.5em;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app .search .search-input button:hover {
  border-color: rgba(66, 143, 160, 1);
  color: rgba(66, 143, 160, 1);
}

.app .search .search-input button:focus {
  border-color: rgba(66, 143, 160, 1);
  color: rgba(66, 143, 160, 1);
}

@media only screen and (max-width: 1000px) {
  .app .search .content {
    padding: 1em;
  }

  .app .search .search-input {
    margin-top: 2em;
  }

  .app .search .search-input .ant-form {
    max-width: 80vw;
  }
}
