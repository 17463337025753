.app .ant-layout-content {
  background: radial-gradient(
    ellipse,
    rgba(190, 190, 190, 0.5) 0%,
    rgba(172, 172, 172, 0.65) 40%,
    rgba(218, 218, 218, 0.25) 100%
  );
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1000px) {
  .app .ant-layout-content {
    background: #eee;
  }
}
