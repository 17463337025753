.app .weather .content {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 0 !important;
}

.app .weather .container {
  padding: 0.75em;
  display: flex;
  justify-content: center;
  width: 50%;
  height: 50vh;
}

.app .weather .container.wide {
  width: 100%;
  max-width: 1200px;
}

.app .weather .container.tall {
  height: 100vh !important;
}

.app .weather .container.mobile {
  min-height: 62vh;
  width: 100%;
  /* display: flex; */
  justify-content: center;
}

.app .weather .container.no-pad {
  padding: 0;
}

.app .weather .camera-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.app .weather iframe {
  flex-grow: 1;
}

.app .weather .tempest-table-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.app .weather .container.mobile .tempest-table-wrapper {
  max-height: 45vh;
  justify-content: flex-start;
  margin: 0.25em 0.5em;
}

.app .weather .tempest-table {
  max-height: 45vh;
  width: 100%;
}

.app .weather .container.mobile .tempest-table {
  margin-top: 2em;
}

.app .weather .tempest-table .ant-table-body {
  max-height: 40vh;
  width: 100%;
  /* font-family: 'Lora', sans-serif; */
}

.app .weather .table-tooltip {
  padding: 0.5em 0.75em;
  border-radius: 1em;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .weather .table-tooltip-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.app .weather .table-tooltip-label {
  margin-right: 0.25em;
}

.app .weather .tempest-switch {
  margin-top: 1em;
  transform: scale(1.5);
  background-color: forestgreen;
}

.app .weather .tempest-switch.ant-switch-checked {
  background-color: rgba(58, 128, 143, 1);
}

.app .weather .hidden {
  display: none;
}

.app .weather .ant-menu-item-active,
.app .weather .ant-menu-item-selected {
  color: rgba(58, 128, 143, 1) !important;
  transform: scale(1.3) translate(2px, -1px);
}

.app .weather .ant-menu-item-active::after,
.app .weather .ant-menu-item-selected::after {
  border-color: rgba(58, 128, 143, 1) !important;
}

.app .weather li.ant-menu-item {
  height: 60px;
  padding: 0;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.35s;
}

.app .weather li.ant-menu-item svg {
  margin-top: 0;
}
