.app .admin {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.app .admin .content {
  display: flex;
  padding-bottom: 0 !important;
  flex-grow: 1;
}

.app .admin .ant-menu {
  width: auto;
  min-width: 36px;
  height: 100%;
  background: #333;
}

.app .admin .main-content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  transition: all 0.35s linear;
  padding-left: 1px;
  background: #ddd;
}

.app .admin .ant-collapse {
  width: 100%;
  flex-grow: 1;
  background: #0000;
}

.app .admin .ant-collapse-header {
  background: rgb(42, 94, 105);
}

.app .admin .ant-collapse-header-text {
  color: #eee;
}

.app .admin .ant-collapse-extra {
  color: #eee;
}

.app .admin .ql-editor {
  background: #eee;
}

.app .admin .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(58, 128, 143, 1);
}

.app .admin .ql-toolbar {
  background: #eee;
}
