.app .edit-product {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .edit-product .product-selector {
  width: 100%;
  display: flex;
}

.app .edit-product .ant-collapse-content .ant-collapse {
  background: none;
}

.app .edit-product .ant-list {
  background: #eee;
  border-radius: 15px;
}

.app .edit-product .ant-list-item {
  cursor: pointer;
  padding: 0.5em 1em;
}

.app .edit-product .ant-list-item:hover h4 {
  color: red;
}
