.app .add-blog {
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1300px;
}

.app .add-blog .top {
  width: 100%;
  display: flex;
}

.app .add-blog .preview {
  margin-bottom: 2em;
  flex-grow: 1;
  text-align: center;
}

.app .add-blog .preview .ant-switch-checked {
  background: rgba(58, 128, 143, 1);
}

.app .add-blog .preview .ant-switch:not(.ant-switch-checked) {
  background: rgb(37, 42, 43);
}

.app .add-blog .previewing {
  width: 100%;
  background: #eee;
  border-radius: 15px;
  padding: 2em 3em;
}

.app .add-blog h1 {
  font-weight: 700;
  font-style: normal;
  font-family: 'Lora', serif;
  font-size: 24px;
  margin-bottom: 17px;
  text-align: center;
}

.app .add-blog .ant-form-item-label {
  width: 150px;
}

.app .add-blog .image-upload {
  width: 100%;
}

.app .add-blog .pending-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.app .add-blog .pending-image {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: #eee;
}

.app .add-blog .pending-image img {
  max-width: 80px;
  margin: 0.25em;
}

.app .add-blog .pending-image .path {
  margin: 0.25em;
}

.app .add-blog .pending-image .delete-button {
  border-radius: 20px;
  background: none;
  padding: 0.25em 0.5em;
  height: auto;
}

.app .add-blog .submit-button {
  margin-top: 1em;
}
