.app .ant-layout-header {
  height: auto;
  padding: 0;
  background: linear-gradient(60deg, rgba(0, 0, 0, 1) 30%, rgba(35, 75, 84, 1) 65%, rgba(58, 128, 143, 1) 100%);
  justify-content: center;
  z-index: 999;
}

.app .ant-layout-header .header-rows {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .ant-layout-header .top-bar {
  width: 100%;
  max-width: 1180px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.app .ant-layout-header .menu-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.app .ant-layout-header .menu-bar > div {
  width: 100%;
}

.app .ant-layout-header .menu {
  width: 100%;
  height: 61px;
  padding-bottom: 1px;
  display: flex;
  justify-content: center;
  box-shadow: rgba(33, 35, 38, 0.25) 0px 14px 14px -10px;
  transition: all 0.35s linear;
}

.app .ant-layout-header .sticky.menu {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: rgba(33, 35, 38, 0.5) 0px 10px 10px -10px;
  background: linear-gradient(60deg, rgba(0, 0, 0, 1) 30%, rgba(35, 75, 84, 1) 65%, rgba(58, 128, 143, 1) 100%);
}

.app .ant-layout-header .menu-container {
  width: 100%;
  max-width: 1180px;
  display: flex;
  justify-content: space-between;
}

.app .ant-layout-header .ant-menu {
  width: 100%;
  background: none;
  border-bottom: 0;
}

.app .ant-layout-header .ant-menu .ant-menu-item,
.app .ant-layout-header .ant-menu .ant-menu-submenu {
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  color: #fff9;
}

.app .ant-layout-header .ant-menu .ant-menu-item-selected,
.app .ant-layout-header .ant-menu .ant-menu-submenu-selected,
.app .ant-layout-header .ant-menu .ant-menu-item:hover,
.app .ant-layout-header .ant-menu .ant-menu-submenu:hover,
.app .ant-layout-header .ant-menu .ant-menu-submenu-title:hover {
  color: #fff;
  background: none;
}

.app .ant-layout-header .ant-menu .ant-menu-item::after,
.app .ant-layout-header .ant-menu .ant-menu-submenu::after,
.app .ant-layout-header .ant-menu .ant-menu-item:hover::after,
.app .ant-layout-header .ant-menu .ant-menu-submenu:hover::after,
.app .ant-layout-header .ant-menu .ant-menu-item-active::after,
.app .ant-layout-header .ant-menu .ant-menu-submenu-active::after,
.app .ant-layout-header .ant-menu .ant-menu-item-open::after,
.app .ant-layout-header .ant-menu .ant-menu-submenu-open::after {
  border-bottom: 0px solid #e32c2b;
}

.app .ant-layout-header .ant-menu .ant-menu-item-selected::after,
.app .ant-layout-header .ant-menu .ant-menu-submenu-selected::after,
.app .ant-layout-header .ant-menu .ant-menu-item-selected:hover::after,
.app .ant-layout-header .ant-menu .ant-menu-submenu-selected:hover::after {
  border-bottom: 3px solid #e32c2b;
  position: absolute;
  bottom: 0;
}

.app .ant-layout-header .search-bar {
  display: flex;
  align-items: center;
}

.app .ant-layout-header .search-bar input {
  height: 44px;
  background: none;
  border: 1px solid #fff9;
  color: #fff;
}

.app .ant-layout-header .search-bar-button {
  font-size: 2em;
  padding: 0.5em;
  height: 64px;
  background: none;
  border: none;
  color: #fff9;
  box-shadow: none;
}

.app .ant-layout-header .search-bar-button:hover {
  color: #fff;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu .ant-menu-item-selected {
  background-color: rgba(58, 128, 143, 1);
}

.ant-drawer-header {
  background: linear-gradient(-120deg, rgba(0, 0, 0, 1) 26%, rgba(35, 75, 84, 1) 78%, rgba(58, 128, 143, 1) 100%);
  border-bottom: 1px solid #eee3 !important;
  padding: 0 !important;
}

.ant-drawer-header-title button {
  color: #eee9;
  margin-left: 0.5em;
  font-size: 1.4em;
}

.ant-drawer-title {
  display: flex;
  justify-content: flex-end;
}

.ant-drawer-title img {
  max-height: 60px;
}

.ant-drawer-body {
  padding: 0 !important;
  background: #222 !important;
}

.ant-drawer-body .ant-menu {
  background: #222 !important;
}

.ant-drawer-body .ant-menu-sub {
  background: #333 !important;
}

.ant-drawer-body .ant-menu-item {
  height: 60px !important;
}

.ant-drawer-body .ant-menu-submenu-title {
  height: 60px !important;
}

.ant-drawer-body .ant-menu-item-selected {
  background-color: rgba(58, 128, 143, 0.75) !important;
}

.ant-drawer-body .ant-menu-submenu-selected {
  background-color: rgba(58, 128, 143, 0.75) !important;
}

.ant-drawer-body .drawer-search {
  display: flex;
  padding: 1em;
}

.ant-drawer-body .drawer-search input {
  background: none;
  color: #eee;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 5px 0 0 5px;
}

.ant-drawer-body .drawer-search button {
  background: none;
  color: #eeec;
  font-size: 1.5em;
  height: 44px;
  padding: 0 0.5em;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .app .ant-layout-header .menu-bar {
    border-top: 0;
  }

  .app .ant-layout-header .menu {
    padding-bottom: 0;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    background: linear-gradient(60deg, rgba(0, 0, 0, 1) 36%, rgba(35, 75, 84, 1) 68%, rgba(58, 128, 143, 1) 100%);
    transition: height 0.35s linear;
  }

  .app .ant-layout-header .menu img {
    height: 100%;
  }

  .app .ant-layout-header .menu-container {
    max-width: 70px;
  }

  .app .ant-layout-header .ant-menu {
    background: none;
  }

  .app .ant-menu-overflow-item svg {
    font-size: 2em;
    margin-top: 0.75em;
  }

  .app .ant-layout-header .sticky.menu {
    height: 61px;
  }

  .app .ant-layout-header .sticky.menu .ant-menu {
    max-height: 61px;
  }

  .app .sticky.menu .ant-menu-overflow-item svg {
    margin-top: 0.5em;
  }

  .app .menu .burger-button {
    background: none;
    border: none;
    color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }

  .app .ant-layout-header .ant-menu .ant-menu-item-selected::after,
  .app .ant-layout-header .ant-menu .ant-menu-submenu-selected::after,
  .app .ant-layout-header .ant-menu .ant-menu-item-selected:hover::after,
  .app .ant-layout-header .ant-menu .ant-menu-submenu-selected:hover::after {
    border-bottom: 0px solid #e32c2b;
  }
}
