.app .blog-display {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app .blog-display img {
  max-height: 50vh;
  max-width: 500px;
  margin-bottom: 2em;
}

.app .blog-display .date {
  margin: 1em;
  font-size: 1.5em;
}

.app .blog-display .text {
  white-space: pre-wrap;
  max-width: 1000px;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 2em;
}

.app .blog-display .text p {
  margin-bottom: 0;
}

.app .blog-display .text p img {
  margin-bottom: 0;
}

@media only screen and (max-width: 1000px) {
  .app .blog-display .text {
    white-space: pre-wrap;
    max-width: 100vw;
    padding: 0.5em;
  }
}
